import React from "react"
import { Seo } from "src/component/common/seo"
import Industries from "src/component/layout/industries"
import { StaticImage } from "gatsby-plugin-image"
import FinanceCasestudyList from "src/component/list/financeCasestudyList"

export default function Finance() {
    return (
        <>
         <Industries>
          <div className="finance inner-content">
            <section className="introduction__mv">
                    <div className="introduction__mv__inner c-inner-primary">
                    <div className="introduction__mv__img">
                            <StaticImage
                              src="../../assets/img/pages/industries/industries_finance.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="pc-show"
                            />
                            <StaticImage
                              src="../../assets/img/pages/industries/industries_finance-sp.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="sp-show"
                            />
                    </div>

                        <div className="introduction__mv__content">
                                <h2 className="introduction__mv__content__title">
                                 <p>金融/IT/サービス</p>
                                  <span>OTHERS</span>
                                </h2>
                                <p className="introduction__mv__lead">
                                    あらゆる分析手法やプロジェクトフェーズ、データ形式に対応し、<br className="c-pc" />DXプロジェクトを成功に導きます。
                                  <br />
                                  マルチモーダルデータ活用をはじめとする高度な分析技術で、<br className="c-pc" />収益最大化と競争力向上を支援します。
                               </p>
                         </div>
        
                    </div>
                </section>
                



                <section className="introduction__content">
                    <div className="introduction__content__inner c-inner-primary">
                        <div className="introduction__content__title">
                            <h2 className="js-scroll">
                                <div><div><span>AIで加速する<br />金融・サービス業界の企業変革</span></div></div>
                            </h2>
                        </div>
                        <div className="introduction__content__textwrap">
                            <ul>
                                <li className="introduction__content__text">
                                 <h3>データ基盤の統合と一元管理</h3>
                                 <p>
                                    顧客データ、取引履歴、外部データを統合し、意思決定を迅速化。
                                 </p>
                                </li>
                                 <li className="introduction__content__text">
                                 <h3>不正取引の検知と防止</h3>
                                 <p>
                                    AIが不正パターンを解析し、不正取引やサイバー攻撃を早期検出。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIによる信用リスク評価と与信管理</h3>
                                 <p>
                                    過去の取引データを分析し、信用リスクをリアルタイムで評価。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>業務プロセスの自動化（RPA + AI）</h3>
                                 <p>
                                    データ入力や帳票処理を自動化し、業務の効率化と人的ミスを削減。
                                 </p>
                                </li>

                            </ul>
         
                        </div>
                    </div>
                </section>

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                        </div>
                        <FinanceCasestudyList />
                    </div>
                </section>
              </div>
            </Industries>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="FINANCE"
            description=""
            pathname=""
        />
    )
}